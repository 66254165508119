
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { EmailQueListRequestDto } from "../../core/types/EmailTypes";
import { DeleteEmailQueRequestDto } from "../../core/types/EmailTypes";

export default defineComponent({
  name: "sent-email-surveys",
  props: ["surveytype"],
  components: {
    TableSkeleton,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);
    const { d } = useI18n();
    const emailQueListRequestDto = ref<EmailQueListRequestDto>({
      userRole: "",
      userAspNetUserId: "",
      typeOfSurvey: props.surveytype,
      fromRoute: "sent-survey",
    });

    const deleteEmailQueRequestDto = ref<DeleteEmailQueRequestDto>({
      id: 0,
      typeOfSurveyEmail: props.surveytype,
    });

    const ques = computed(() => {
      return store.getters.getAllQuedEmails;
    });
    const searchEmails = ref("");

    onMounted(() => {
      setCurrentPageTitle("Emails");
      setCurrentPageBreadcrumbs("Sent Surveys", ["Behavioral Surveys"]);

      emailQueListRequestDto.value.userRole = currentUser.role;
      emailQueListRequestDto.value.userAspNetUserId = currentUser.id;
      store
        .dispatch(Actions.GET_ALL_EMAIL_QUES, emailQueListRequestDto.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving all your sent emails.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const deleteEmail = (id) => {
      Swal.fire({
        title: "Are you sure you want to remove this email?",
        showCancelButton: true,
        confirmButtonText: "Yes. Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteEmailQueRequestDto.value.id = id;

          store
            .dispatch(Actions.DELETE_EMAIL_QUE, deleteEmailQueRequestDto.value)
            .then(() => {
              Swal.fire("The email has been removed!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem removing the email.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const filterEmails = computed(() => {
      return ques.value.filter((emailQue) => {
        return (
          emailQue.recipientEmail
            .toLowerCase()
            .indexOf(searchEmails.value.toLowerCase()) != -1 ||
          emailQue.recipientLastName
            .toLowerCase()
            .indexOf(searchEmails.value.toLowerCase()) != -1
        );
      });
    });

    watch(ques, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      isDataReady,
      ques,
      d,
      deleteEmail,
      deleteEmailQueRequestDto,
      emailQueListRequestDto,
      searchEmails,
      filterEmails,
    };
  },
});
